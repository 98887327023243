import { IconName, IconStroke } from '@codiwork/codi';
import { ReactComponent as AddReaction } from '@codiwork/codi/lib/icons/AddReaction.svg';
import { ReactComponent as AddReactionX2 } from '@codiwork/codi/lib/icons/AddReactionX2.svg';
import { ReactComponent as AddressPin } from '@codiwork/codi/lib/icons/AddressPin.svg';
import { ReactComponent as AddressPinOutline } from '@codiwork/codi/lib/icons/AddressPinOutline.svg';
import { ReactComponent as AddressPinOutlineX2 } from '@codiwork/codi/lib/icons/AddressPinOutlineX2.svg';
import { ReactComponent as Archive } from '@codiwork/codi/lib/icons/Archive.svg';
import { ReactComponent as ArchiveX2 } from '@codiwork/codi/lib/icons/ArchiveX2.svg';
import { ReactComponent as Arrow } from '@codiwork/codi/lib/icons/Arrow.svg';
import { ReactComponent as ArrowX2 } from '@codiwork/codi/lib/icons/ArrowX2.svg';
import { ReactComponent as Attachment } from '@codiwork/codi/lib/icons/Attachment.svg';
import { ReactComponent as Avatar } from '@codiwork/codi/lib/icons/Avatar.svg';
import { ReactComponent as Beverage } from '@codiwork/codi/lib/icons/Beverage.svg';
import { ReactComponent as BeverageSupplies } from '@codiwork/codi/lib/icons/BeverageSupplies.svg';
import { ReactComponent as BeverageSuppliesX2 } from '@codiwork/codi/lib/icons/BeverageSuppliesX2.svg';
import { ReactComponent as Bike } from '@codiwork/codi/lib/icons/Bike.svg';
import { ReactComponent as BikeX2 } from '@codiwork/codi/lib/icons/BikeX2.svg';
import { ReactComponent as Building } from '@codiwork/codi/lib/icons/Building.svg';
import { ReactComponent as BuildingNew } from '@codiwork/codi/lib/icons/BuildingNew.svg';
import { ReactComponent as BuildingRetail } from '@codiwork/codi/lib/icons/BuildingRetail.svg';
import { ReactComponent as BuildingRetailX2 } from '@codiwork/codi/lib/icons/BuildingRetailX2.svg';
import { ReactComponent as BuildingX2 } from '@codiwork/codi/lib/icons/BuildingX2.svg';
import { ReactComponent as Calendar } from '@codiwork/codi/lib/icons/Calendar.svg';
import { ReactComponent as CalendarEdit } from '@codiwork/codi/lib/icons/CalendarEdit.svg';
import { ReactComponent as CalendarEditX2 } from '@codiwork/codi/lib/icons/CalendarEditX2.svg';
import { ReactComponent as CalendarX2 } from '@codiwork/codi/lib/icons/CalendarX2.svg';
import { ReactComponent as Callout } from '@codiwork/codi/lib/icons/Callout.svg';
import { ReactComponent as Camera } from '@codiwork/codi/lib/icons/Camera.svg';
import { ReactComponent as Caret } from '@codiwork/codi/lib/icons/Caret.svg';
import { ReactComponent as CaretX2 } from '@codiwork/codi/lib/icons/CaretX2.svg';
import { ReactComponent as CenterMap } from '@codiwork/codi/lib/icons/CenterMap.svg';
import { ReactComponent as CenterMapX2 } from '@codiwork/codi/lib/icons/CenterMapX2.svg';
import { ReactComponent as Chair } from '@codiwork/codi/lib/icons/Chair.svg';
import { ReactComponent as Check } from '@codiwork/codi/lib/icons/Check.svg';
import { ReactComponent as Checkmark } from '@codiwork/codi/lib/icons/Checkmark.svg';
import { ReactComponent as CheckmarkX2 } from '@codiwork/codi/lib/icons/CheckmarkX2.svg';
import { ReactComponent as Chevron } from '@codiwork/codi/lib/icons/Chevron.svg';
import { ReactComponent as ChevronX2 } from '@codiwork/codi/lib/icons/ChevronX2.svg';
import { ReactComponent as Cleaning } from '@codiwork/codi/lib/icons/Cleaning.svg';
import { ReactComponent as CleaningDeep } from '@codiwork/codi/lib/icons/CleaningDeep.svg';
import { ReactComponent as CleaningDeepX2 } from '@codiwork/codi/lib/icons/CleaningDeepX2.svg';
import { ReactComponent as CleaningLight } from '@codiwork/codi/lib/icons/CleaningLight.svg';
import { ReactComponent as CleaningLightX2 } from '@codiwork/codi/lib/icons/CleaningLightX2.svg';
import { ReactComponent as Clear } from '@codiwork/codi/lib/icons/Clear.svg';
import { ReactComponent as ClipboardNoPageFold } from '@codiwork/codi/lib/icons/ClipBoardNoPageFold.svg';
import { ReactComponent as Clipboard } from '@codiwork/codi/lib/icons/Clipboard.svg';
import { ReactComponent as Clock } from '@codiwork/codi/lib/icons/Clock.svg';
import { ReactComponent as ClockX2 } from '@codiwork/codi/lib/icons/ClockX2.svg';
import { ReactComponent as Close } from '@codiwork/codi/lib/icons/Close.svg';
import { ReactComponent as CloseX2 } from '@codiwork/codi/lib/icons/CloseX2.svg';
import { ReactComponent as Coffee } from '@codiwork/codi/lib/icons/Coffee.svg';
import { ReactComponent as Comment } from '@codiwork/codi/lib/icons/Comment.svg';
import { ReactComponent as Copy } from '@codiwork/codi/lib/icons/Copy.svg';
import { ReactComponent as CreditCard } from '@codiwork/codi/lib/icons/CreditCard.svg';
import { ReactComponent as Dashboard } from '@codiwork/codi/lib/icons/Dashboard.svg';
import { ReactComponent as Desk } from '@codiwork/codi/lib/icons/Desk.svg';
import { ReactComponent as DeskStanding } from '@codiwork/codi/lib/icons/DeskStanding.svg';
import { ReactComponent as Detector } from '@codiwork/codi/lib/icons/Detector.svg';
import { ReactComponent as DollarSign } from '@codiwork/codi/lib/icons/DollarSign.svg';
import { ReactComponent as DollarSignX2 } from '@codiwork/codi/lib/icons/DollarSignX2.svg';
import { ReactComponent as Download } from '@codiwork/codi/lib/icons/Download.svg';
import { ReactComponent as EditPen } from '@codiwork/codi/lib/icons/EditPen.svg';
import { ReactComponent as EditPenX2 } from '@codiwork/codi/lib/icons/EditPenX2.svg';
import { ReactComponent as Ellipsis } from '@codiwork/codi/lib/icons/Ellipsis.svg';
import { ReactComponent as Envelope } from '@codiwork/codi/lib/icons/Envelope.svg';
import { ReactComponent as EnvelopeX2 } from '@codiwork/codi/lib/icons/EnvelopeX2.svg';
import { ReactComponent as Exclamation } from '@codiwork/codi/lib/icons/Exclamation.svg';
import { ReactComponent as ExclamationX2 } from '@codiwork/codi/lib/icons/ExclamationX2.svg';
import { ReactComponent as ExternalLink } from '@codiwork/codi/lib/icons/ExternalLink.svg';
import { ReactComponent as ExternalLinkX2 } from '@codiwork/codi/lib/icons/ExternalLinkX2.svg';
import { ReactComponent as Fan } from '@codiwork/codi/lib/icons/Fan.svg';
import { ReactComponent as FeatherDuster } from '@codiwork/codi/lib/icons/FeatherDuster.svg';
import { ReactComponent as FeatherDusterX2 } from '@codiwork/codi/lib/icons/FeatherDusterX2.svg';
import { ReactComponent as Filter } from '@codiwork/codi/lib/icons/Filter.svg';
import { ReactComponent as Flag } from '@codiwork/codi/lib/icons/Flag.svg';
import { ReactComponent as FlagX2 } from '@codiwork/codi/lib/icons/FlagX2.svg';
import { ReactComponent as Flame } from '@codiwork/codi/lib/icons/Flame.svg';
import { ReactComponent as FlameOutline } from '@codiwork/codi/lib/icons/FlameOutline.svg';
import { ReactComponent as FlameOutlineX2 } from '@codiwork/codi/lib/icons/FlameOutlineX2.svg';
import { ReactComponent as Flipchart } from '@codiwork/codi/lib/icons/Flipchart.svg';
import { ReactComponent as Gift } from '@codiwork/codi/lib/icons/Gift.svg';
import { ReactComponent as GiftX2 } from '@codiwork/codi/lib/icons/GiftX2.svg';
import { ReactComponent as Hamburger } from '@codiwork/codi/lib/icons/Hamburger.svg';
import { ReactComponent as HamburgerX2 } from '@codiwork/codi/lib/icons/HamburgerX2.svg';
import { ReactComponent as Handle } from '@codiwork/codi/lib/icons/Handle.svg';
import { ReactComponent as Heart } from '@codiwork/codi/lib/icons/Heart.svg';
import { ReactComponent as HeartOutline } from '@codiwork/codi/lib/icons/HeartOutline.svg';
import { ReactComponent as HeartOutlineX2 } from '@codiwork/codi/lib/icons/HeartOutlineX2.svg';
import { ReactComponent as House } from '@codiwork/codi/lib/icons/House.svg';
import { ReactComponent as Image } from '@codiwork/codi/lib/icons/Image.svg';
import { ReactComponent as Images } from '@codiwork/codi/lib/icons/Images.svg';
import { ReactComponent as ImagesX2 } from '@codiwork/codi/lib/icons/ImagesX2.svg';
import { ReactComponent as Info } from '@codiwork/codi/lib/icons/Info.svg';
import { ReactComponent as KeyCard } from '@codiwork/codi/lib/icons/KeyCard.svg';
import { ReactComponent as KeyCardX2 } from '@codiwork/codi/lib/icons/KeyCardX2.svg';
import { ReactComponent as Kitchen } from '@codiwork/codi/lib/icons/Kitchen.svg';
import { ReactComponent as List } from '@codiwork/codi/lib/icons/List.svg';
import { ReactComponent as ListX2 } from '@codiwork/codi/lib/icons/ListX2.svg';
import { ReactComponent as LocationArrow } from '@codiwork/codi/lib/icons/LocationArrow.svg';
import { ReactComponent as LocationArrowOutline } from '@codiwork/codi/lib/icons/LocationArrowOutline.svg';
import { ReactComponent as Lock } from '@codiwork/codi/lib/icons/Lock.svg';
import { ReactComponent as LockX2 } from '@codiwork/codi/lib/icons/LockX2.svg';
import { ReactComponent as Map } from '@codiwork/codi/lib/icons/Map.svg';
import { ReactComponent as MapX2 } from '@codiwork/codi/lib/icons/MapX2.svg';
import { ReactComponent as MeetingRoom } from '@codiwork/codi/lib/icons/MeetingRoom.svg';
import { ReactComponent as Membership } from '@codiwork/codi/lib/icons/Membership.svg';
import { ReactComponent as Membership2 } from '@codiwork/codi/lib/icons/Membership2.svg';
import { ReactComponent as Microwave } from '@codiwork/codi/lib/icons/Microwave.svg';
import { ReactComponent as Minus } from '@codiwork/codi/lib/icons/Minus.svg';
import { ReactComponent as MinusX2 } from '@codiwork/codi/lib/icons/MinusX2.svg';
import { ReactComponent as Miscellaneous } from '@codiwork/codi/lib/icons/Miscellaneous.svg';
import { ReactComponent as MiscellaneousX2 } from '@codiwork/codi/lib/icons/Miscellaneous.svg';
import { ReactComponent as MobilePhone } from '@codiwork/codi/lib/icons/MobilePhone.svg';
import { ReactComponent as Monitor } from '@codiwork/codi/lib/icons/Monitor.svg';
import { ReactComponent as Music } from '@codiwork/codi/lib/icons/Music.svg';
import { ReactComponent as Newspaper } from '@codiwork/codi/lib/icons/Newspaper.svg';
import { ReactComponent as NoSymbol } from '@codiwork/codi/lib/icons/NoSymbol.svg';
import { ReactComponent as NoSymbolX2 } from '@codiwork/codi/lib/icons/NoSymbolX2.svg';
import { ReactComponent as Noise } from '@codiwork/codi/lib/icons/Noise.svg';
import { ReactComponent as Notification } from '@codiwork/codi/lib/icons/Notification.svg';
import { ReactComponent as NotificationX2 } from '@codiwork/codi/lib/icons/NotificationX2.svg';
import { ReactComponent as Outdoors } from '@codiwork/codi/lib/icons/Outdoors.svg';
import { ReactComponent as Outdoors2 } from '@codiwork/codi/lib/icons/Outdoors2.svg';
import { ReactComponent as OutdoorsFlower } from '@codiwork/codi/lib/icons/OutdoorsFlower.svg';
import { ReactComponent as Page } from '@codiwork/codi/lib/icons/Page.svg';
import { ReactComponent as PageBlank } from '@codiwork/codi/lib/icons/PageBlank.svg';
import { ReactComponent as Parking } from '@codiwork/codi/lib/icons/Parking.svg';
import { ReactComponent as Payout } from '@codiwork/codi/lib/icons/Payout.svg';
import { ReactComponent as PayoutX2 } from '@codiwork/codi/lib/icons/PayoutX2.svg';
import { ReactComponent as Pets } from '@codiwork/codi/lib/icons/Pets.svg';
import { ReactComponent as Phone } from '@codiwork/codi/lib/icons/Phone.svg';
import { ReactComponent as Plus } from '@codiwork/codi/lib/icons/Plus.svg';
import { ReactComponent as PlusX2 } from '@codiwork/codi/lib/icons/PlusX2.svg';
import { ReactComponent as Printer } from '@codiwork/codi/lib/icons/Printer.svg';
import { ReactComponent as Privacy } from '@codiwork/codi/lib/icons/Privacy.svg';
import { ReactComponent as Projector } from '@codiwork/codi/lib/icons/Projector.svg';
import { ReactComponent as QuestionCircle } from '@codiwork/codi/lib/icons/QuestionCircle.svg';
import { ReactComponent as QuestionCircleX2 } from '@codiwork/codi/lib/icons/QuestionCircleX2.svg';
import { ReactComponent as QuestionMark } from '@codiwork/codi/lib/icons/QuestionMark.svg';
import { ReactComponent as QuestionMarkX2 } from '@codiwork/codi/lib/icons/QuestionMarkX2.svg';
import { ReactComponent as Refresh } from '@codiwork/codi/lib/icons/Refresh.svg';
import { ReactComponent as Reservation } from '@codiwork/codi/lib/icons/Reservation.svg';
import { ReactComponent as ReservationX2 } from '@codiwork/codi/lib/icons/ReservationX2.svg';
import { ReactComponent as Ruler } from '@codiwork/codi/lib/icons/Ruler.svg';
import { ReactComponent as Scale } from '@codiwork/codi/lib/icons/Scale.svg';
import { ReactComponent as Search } from '@codiwork/codi/lib/icons/Search.svg';
import { ReactComponent as SearchX2 } from '@codiwork/codi/lib/icons/SearchX2.svg';
import { ReactComponent as Settings } from '@codiwork/codi/lib/icons/Settings.svg';
import { ReactComponent as SettingsX2 } from '@codiwork/codi/lib/icons/SettingsX2.svg';
import { ReactComponent as Share } from '@codiwork/codi/lib/icons/Share.svg';
import { ReactComponent as ShoppingCart } from '@codiwork/codi/lib/icons/ShoppingCart.svg';
import { ReactComponent as ShoppingCartX2 } from '@codiwork/codi/lib/icons/ShoppingCartX2.svg';
import { ReactComponent as SmartLock } from '@codiwork/codi/lib/icons/SmartLock.svg';
import { ReactComponent as Snack } from '@codiwork/codi/lib/icons/Snacks.svg';
import { ReactComponent as Sort } from '@codiwork/codi/lib/icons/Sort.svg';
import { ReactComponent as SortAsc } from '@codiwork/codi/lib/icons/SortAsc.svg';
import { ReactComponent as SortControl } from '@codiwork/codi/lib/icons/SortControl.svg';
import { ReactComponent as SortDesc } from '@codiwork/codi/lib/icons/SortDesc.svg';
import { ReactComponent as Sparkle } from '@codiwork/codi/lib/icons/Sparkle.svg';
import { ReactComponent as SparklingWater } from '@codiwork/codi/lib/icons/SparklingWater.svg';
import { ReactComponent as SparklingWaterX2 } from '@codiwork/codi/lib/icons/SparklingWaterX2.svg';
import { ReactComponent as Star } from '@codiwork/codi/lib/icons/Star.svg';
import { ReactComponent as StarOutline } from '@codiwork/codi/lib/icons/StarOutline.svg';
import { ReactComponent as StreetParking } from '@codiwork/codi/lib/icons/StreetParking.svg';
import { ReactComponent as Support } from '@codiwork/codi/lib/icons/Support.svg';
import { ReactComponent as Surveillance } from '@codiwork/codi/lib/icons/Surveillance.svg';
import { ReactComponent as TV } from '@codiwork/codi/lib/icons/TV.svg';
import { ReactComponent as TableLarge } from '@codiwork/codi/lib/icons/TableLarge.svg';
import { ReactComponent as TableSmall } from '@codiwork/codi/lib/icons/TableSmall.svg';
import { ReactComponent as ThumbsDown } from '@codiwork/codi/lib/icons/ThumbsDown.svg';
import { ReactComponent as ThumbsDownFilled } from '@codiwork/codi/lib/icons/ThumbsDownFilled.svg';
import { ReactComponent as ThumbsDownFilledX2 } from '@codiwork/codi/lib/icons/ThumbsDownFilledX2.svg';
import { ReactComponent as ThumbsDownX2 } from '@codiwork/codi/lib/icons/ThumbsDownX2.svg';
import { ReactComponent as ThumbsUp } from '@codiwork/codi/lib/icons/ThumbsUp.svg';
import { ReactComponent as ThumbsUpFilled } from '@codiwork/codi/lib/icons/ThumbsUpFilled.svg';
import { ReactComponent as ThumbsUpFilledX2 } from '@codiwork/codi/lib/icons/ThumbsUpFilledX2.svg';
import { ReactComponent as ThumbsUpX2 } from '@codiwork/codi/lib/icons/ThumbsUpX2.svg';
import { ReactComponent as Ticket } from '@codiwork/codi/lib/icons/Ticket.svg';
import { ReactComponent as TicketX2 } from '@codiwork/codi/lib/icons/TicketX2.svg';
import { ReactComponent as Transit } from '@codiwork/codi/lib/icons/Transit.svg';
import { ReactComponent as Transportation } from '@codiwork/codi/lib/icons/Transportation.svg';
import { ReactComponent as TransportationX2 } from '@codiwork/codi/lib/icons/TransportationX2.svg';
import { ReactComponent as Trashcan } from '@codiwork/codi/lib/icons/Trashcan.svg';
import { ReactComponent as TrashcanX2 } from '@codiwork/codi/lib/icons/TrashcanX2.svg';
import { ReactComponent as Upload } from '@codiwork/codi/lib/icons/Upload.svg';
import { ReactComponent as UploadCloud } from '@codiwork/codi/lib/icons/UploadCloud.svg';
import { ReactComponent as UploadCloudX2 } from '@codiwork/codi/lib/icons/UploadCloudX2.svg';
import { ReactComponent as UserStar } from '@codiwork/codi/lib/icons/UserStar.svg';
import { ReactComponent as UserStarX2 } from '@codiwork/codi/lib/icons/UserStarX2.svg';
import { ReactComponent as Users } from '@codiwork/codi/lib/icons/Users.svg';
import { ReactComponent as UsersX2 } from '@codiwork/codi/lib/icons/UsersX2.svg';
import { ReactComponent as Walk } from '@codiwork/codi/lib/icons/Walk.svg';
import { ReactComponent as WalkX2 } from '@codiwork/codi/lib/icons/WalkX2.svg';
import { ReactComponent as Watch } from '@codiwork/codi/lib/icons/Watch.svg';
import { ReactComponent as WatchX2 } from '@codiwork/codi/lib/icons/WatchX2.svg';
import { ReactComponent as Watching } from '@codiwork/codi/lib/icons/Watching.svg';
import { ReactComponent as WatchingX2 } from '@codiwork/codi/lib/icons/WatchingX2.svg';
import { ReactComponent as Whiteboard } from '@codiwork/codi/lib/icons/Whiteboard.svg';
import { ReactComponent as WiFi } from '@codiwork/codi/lib/icons/WiFi.svg';

export const ICON_NAME_TO_SVG: Record<IconStroke, Partial<Record<IconName, React.FunctionComponent>>> = {
  1.5: {
    addReaction: AddReaction,
    addressPin: AddressPin,
    addressPinOutline: AddressPinOutline,
    archive: Archive,
    attachment: Attachment,
    avatar: Avatar,
    beverage: Beverage,
    beverageSupplies: BeverageSupplies,
    bike: Bike,
    building: Building,
    buildingNew: BuildingNew,
    buildingRetail: BuildingRetail,
    calendar: Calendar,
    callout: Callout,
    camera: Camera,
    centerMap: CenterMap,
    chair: Chair,
    check: Check,
    checkmark: Checkmark,
    cleaning: Cleaning,
    cleaningDeep: CleaningDeep,
    cleaningLight: CleaningLight,
    clear: Clear,
    clipboardNoPageFold: ClipboardNoPageFold,
    clipboard: Clipboard,
    clock: Clock,
    close: Close,
    coffee_tea: Coffee,
    coffee: Coffee,
    comfortable_chairs: Chair, // TODO: fix in db and remove
    comment: Comment,
    copy: Copy,
    creditCard: CreditCard,
    dashboard: Dashboard,
    desk: Desk,
    detector: Detector,
    dollarSign: DollarSign,
    downArrow: Arrow,
    downCaret: Caret,
    downChevron: Chevron,
    download: Download,
    editPen: EditPen,
    ellipsisH: Ellipsis,
    ellipsisV: Ellipsis,
    envelope: Envelope,
    exclamation: Exclamation,
    externalLink: ExternalLink,
    fan: Fan,
    featherDuster: FeatherDuster,
    filter: Filter,
    flag: Flag,
    flame: Flame,
    flameOutline: FlameOutline,
    flipchart: Flipchart,
    gift: Gift,
    hamburger: Hamburger,
    handle: Handle,
    heart: Heart,
    heartOutline: HeartOutline,
    house: House,
    image: Image,
    images: Images,
    info: Info,
    keyCard: KeyCard,
    kitchen: Kitchen,
    leftArrow: Arrow,
    leftCaret: Caret,
    leftChevron: Chevron,
    list: List,
    locationArrow: LocationArrow,
    locationArrowOutline: LocationArrowOutline,
    lock: Lock,
    map: Map,
    meeting_room: MeetingRoom, // TODO: fix in db and remove
    meetingRoom: MeetingRoom,
    membership: Membership,
    membership2: Membership2,
    microwave: Microwave,
    minus: Minus,
    mobilePhone: MobilePhone,
    monitor: Monitor,
    music: Music,
    newspaper: Newspaper,
    noise: Noise,
    notification: Notification,
    outdoors: Outdoors,
    outdoors2: Outdoors2,
    outdoorsFlower: OutdoorsFlower,
    page: Page,
    pageBlank: PageBlank,
    parking: StreetParking,
    payout: Payout,
    pets: Pets,
    phone_call_area: Phone,
    phone: Phone,
    plus: Plus,
    printer: Printer,
    privacy: Privacy,
    private_parking: Parking,
    projector: Projector,
    questionCircle: QuestionCircle,
    questionMark: QuestionMark,
    refresh: Refresh,
    reservation: Reservation,
    rightArrow: Arrow,
    rightCaret: Caret,
    rightChevron: Chevron,
    ruler: Ruler,
    scale: Scale,
    search: Search,
    settings: Settings,
    share: Share,
    shoppingCart: ShoppingCart,
    smart_lock: SmartLock,
    snack: Snack,
    sort: Sort,
    sortAsc: SortAsc,
    sortControl: SortControl,
    sortDesc: SortDesc,
    sparkle: Sparkle,
    sparklingWater: SparklingWater,
    standing_desk: DeskStanding,
    star: Star,
    starOutline: StarOutline,
    support: Support,
    surveillance: Surveillance,
    tableLarge: TableLarge,
    tableSmall: TableSmall,
    thumbsDown: ThumbsDown,
    thumbsDownFilled: ThumbsDownFilled,
    thumbsUp: ThumbsUp,
    thumbsUpFilled: ThumbsUpFilled,
    ticket: Ticket,
    transit: Transit,
    transportation: Transportation,
    trashcan: Trashcan,
    tv: TV,
    users: Users,
    userStar: UserStar,
    upArrow: Arrow,
    upCaret: Caret,
    upChevron: Chevron,
    upload: Upload,
    uploadCloud: UploadCloud,
    whiteboard: Whiteboard,
    walk: Walk,
    wifi: WiFi,
    miscellaneous: Miscellaneous,
    noSymbol: NoSymbol,
    calendarEdit: CalendarEdit,
    watch: Watch,
    watching: Watching
  },
  2: {
    addReaction: AddReactionX2,
    addressPinOutline: AddressPinOutlineX2,
    archive: ArchiveX2,
    beverageSupplies: BeverageSuppliesX2,
    buildingRetail: BuildingRetailX2,
    bike: BikeX2,
    building: BuildingX2,
    calendar: CalendarX2,
    centerMap: CenterMapX2,
    checkmark: CheckmarkX2,
    cleaningDeep: CleaningDeepX2,
    cleaningLight: CleaningLightX2,
    clock: ClockX2,
    close: CloseX2,
    dollarSign: DollarSignX2,
    downArrow: ArrowX2,
    downCaret: CaretX2,
    downChevron: ChevronX2,
    editPen: EditPenX2,
    envelope: EnvelopeX2,
    exclamation: ExclamationX2,
    externalLink: ExternalLinkX2,
    featherDuster: FeatherDusterX2,
    flag: FlagX2,
    flameOutline: FlameOutlineX2,
    hamburger: HamburgerX2,
    heartOutline: HeartOutlineX2,
    gift: GiftX2,
    images: ImagesX2,
    keyCard: KeyCardX2,
    leftArrow: ArrowX2,
    leftCaret: CaretX2,
    leftChevron: ChevronX2,
    list: ListX2,
    lock: LockX2,
    map: MapX2,
    minus: MinusX2,
    notification: NotificationX2,
    payout: PayoutX2,
    plus: PlusX2,
    questionCircle: QuestionCircleX2,
    questionMark: QuestionMarkX2,
    reservation: ReservationX2,
    rightArrow: ArrowX2,
    rightCaret: CaretX2,
    rightChevron: ChevronX2,
    search: SearchX2,
    settings: SettingsX2,
    shoppingCart: ShoppingCartX2,
    sparklingWater: SparklingWaterX2,
    thumbsDown: ThumbsDownX2,
    thumbsDownFilled: ThumbsDownFilledX2,
    thumbsUp: ThumbsUpX2,
    thumbsUpFilled: ThumbsUpFilledX2,
    ticket: TicketX2,
    transportation: TransportationX2,
    trashcan: TrashcanX2,
    users: UsersX2,
    upArrow: ArrowX2,
    upCaret: CaretX2,
    upChevron: ChevronX2,
    uploadCloud: UploadCloudX2,
    userStar: UserStarX2,
    walk: WalkX2,
    watching: WatchingX2,
    watch: WatchX2,
    miscellaneous: MiscellaneousX2,
    noSymbol: NoSymbolX2,
    calendarEdit: CalendarEditX2
  }
};
