import React from 'react';
import { Color } from '@codiwork/codi';

import Layout, { Props as LayoutProps } from './Layout';

interface Props extends LayoutProps {
  dimension: number;
  color: Color;
  children?: React.ReactNode;
}

const Circle: React.FC<Props> = ({ dimension, color, children, ...props }) => {
  return (
    <Layout
      width={dimension}
      height={dimension}
      borderRadius="circular"
      color={color}
      align="center"
      justify="center"
      {...props}
    >
      {children}
    </Layout>
  );
};

export default Circle;
