import { useEffect } from 'react';

export default function useKeyUp(handleKeyPress: (event: KeyboardEvent) => void) {
  useEffect(() => {
    const upHandler = (ev: KeyboardEvent) => handleKeyPress(ev);

    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, [handleKeyPress]);
}
