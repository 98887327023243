import { DateTimeFormat, formatDate } from '@codiwork/codi';
import { DateTime, DurationLike } from 'luxon';

import { isSameDate } from 'helpers/time';

export const formatDates = ({
  date,
  format = 'MED_WITH_SHORT_WEEKDAY',
  startDate,
  endDate
}: {
  date?: Date | null;
  startDate?: Date | null;
  endDate?: Date | null;
  format?: DateTimeFormat;
}) => {
  if (date) {
    return formatDate({ date, format });
  } else if (startDate && endDate && isSameDate(startDate, endDate)) {
    return formatDate({ date: startDate, format });
  } else if (startDate && endDate) {
    return `${formatDate({ date: startDate, format })} - ${formatDate({ date: endDate, format })}`;
  } else if (startDate) {
    return formatDate({ date: startDate, format });
  }
};

export interface DatePickerTimeConfig {
  hour: number;
  minute: number;
}

export function makeTimeRangeStartOptions({
  min,
  max,
  increment
}: {
  min: DatePickerTimeConfig;
  max: DatePickerTimeConfig;
  increment: DurationLike;
}) {
  const times = [];
  const now = DateTime.now();

  let time = now.set(min);
  let lastStartTime;

  lastStartTime = now.set(max);

  while (time <= lastStartTime) {
    times.push({ value: time.toFormat('HH:mm'), label: time.toFormat('h:mm a') });
    time = time.plus(increment);
  }

  return times;
}

export function makeTimeRangeEndOptions({
  startTime,
  min,
  max,
  increment
}: {
  startTime?: string;
  min: DatePickerTimeConfig;
  max: DatePickerTimeConfig;
  increment: DurationLike;
}) {
  const times = [];
  const now = DateTime.now();

  let time;
  let lastEndTime = now.set(max);

  if (startTime) {
    const startDateTime = DateTime.fromFormat(startTime, 'HH:mm');
    time = now.set({ hour: startDateTime.hour, minute: startDateTime.minute }).plus(increment);
  } else {
    time = now.set(min).plus(increment);
  }

  while (time <= lastEndTime) {
    times.push({ value: time.toFormat('HH:mm'), label: time.toFormat('h:mm a') });
    time = time.plus(increment);
  }

  return times;
}
