import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { IS_TOUCH_DEVICE } from 'ds/constants';
import { useOnClickOutside } from 'ds/helpers';
import useUniqueId from 'ds/helpers/useUniqueId';
import TextInput, { Props as TextInputProps } from 'ds/inputs/TextInput';
import { InputImageIconProps } from 'ds/inputs/types';
import { FieldSize } from 'ds/types';
import { useListFocus } from 'hooks';
import useFieldSize from 'hooks/useFieldSize';

export type Props<T> = Pick<
  TextInputProps,
  | 'onChange'
  | 'label'
  | 'bigLabel'
  | 'size'
  | 'disabled'
  | 'onClear'
  | 'placeholder'
  | 'icon'
  | 'autoFocus'
  | 'onBlur'
  | 'isReadOnly'
> &
  InputImageIconProps & {
    results: Result<T>[];
    noMatchesItem?: JSX.Element;
    forceShowNoMatchesItem?: boolean;
    query?: string;
    onSelect: (value: T) => void;
    showResultsWhenEmpty?: boolean;
    maxItems?: number;
    maxVisibleItems?: number;
    highlightMatch?: boolean;
    required?: boolean;
    hideLabel?: boolean;
    error?: string;
    onCreateNew?: VoidFunction;
  } & ComboxBoxProps;

type ResultProps<T> = Pick<Props<T>, 'results' | 'onSelect' | 'highlightMatch'>;

export interface ComboxBoxProps {
  readOnlyHref?: string;
}

export interface Result<T> {
  value: T;
  element: JSX.Element;
  disabled?: boolean;
}

const CLASSNAME = 'ComboBox';

const ComboBox = <T extends string | number>({
  label,
  hideLabel = false,
  query,
  onChange,
  onSelect,
  showResultsWhenEmpty = false,
  maxItems = 8,
  highlightMatch,
  disabled,
  onClear,
  placeholder,
  required = false,
  error,
  noMatchesItem,
  forceShowNoMatchesItem,
  maxVisibleItems,
  onCreateNew,
  isReadOnly,
  readOnlyHref,
  ...props
}: Props<T>) => {
  const scaledSize = useFieldSize();
  const [resultsVisible, setResultsVisible] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const size = props.size || scaledSize;
  const hasQuery = query && query.length > 0;
  let results = hasQuery ? props.results : showResultsWhenEmpty ? props.results : [];
  const history = useHistory();

  const [suppressValidation, setSuppressValidation] = useState<boolean>();
  const id = useUniqueId();

  const showNoMatchesItem = (results.length === 0 || !!forceShowNoMatchesItem) && !!noMatchesItem;

  useOnClickOutside(ref, () => {
    setResultsVisible(false);
  });

  return (
    <div
      id={id}
      className={classNames(CLASSNAME, `is-size-${size}`, { 'is-link': readOnlyHref })}
      ref={ref}
      {...(readOnlyHref && isReadOnly
        ? {
            role: 'button',
            onClick: () => {
              readOnlyHref.startsWith('http') ? window.open(readOnlyHref, '_blank') : history.push(readOnlyHref);
            }
          }
        : {})}
      onBlur={() => {
        if (!IS_TOUCH_DEVICE || isReadOnly) return;

        setTimeout(() => {
          setResultsVisible(false);
        }, 35);
      }}
    >
      <TextInput
        {...props}
        label={label}
        hideLabel={hideLabel}
        value={query}
        onChange={params => {
          onChange(params);
          if (!resultsVisible) {
            setResultsVisible(true);
          }
        }}
        size={size}
        onFocus={() => {
          setResultsVisible(true);
        }}
        disabled={disabled}
        onClear={onClear}
        placeholder={placeholder}
        required={required}
        suppressValidation={suppressValidation}
        onKeyDown={({ key }) => {
          if (key === 'Tab') {
            setResultsVisible(false);
          }
        }}
        isReadOnly={isReadOnly}
        error={error}
      />
      <ResultList
        results={results.slice(0, maxItems)}
        noMatchesItem={noMatchesItem}
        showNoMatchesItem={showNoMatchesItem}
        onSelect={result => {
          setSuppressValidation(false);
          onSelect(result);
          setResultsVisible(false);
        }}
        onCreateNew={onCreateNew}
        onMouseDown={() => {
          setSuppressValidation(true);
        }}
        maxVisibleItems={maxVisibleItems}
        size={size}
        highlightMatch={highlightMatch}
        query={query}
        divId={id}
        setResultsVisible={setResultsVisible}
        isVisible={resultsVisible}
        key={showNoMatchesItem.toString()}
      />
    </div>
  );
};

interface ResultListProps<T> extends ResultProps<T> {
  size: FieldSize;
  query?: string;
  onMouseDown: VoidFunction;
  isVisible?: boolean;
  noMatchesItem?: JSX.Element;
  showNoMatchesItem: boolean;
  onCreateNew?: VoidFunction;
  setResultsVisible: (isVisible: boolean) => void;
  divId: string;
  maxVisibleItems?: number;
}

const ResultList = <T extends string | number>({
  results,
  size,
  onSelect,
  onMouseDown,
  highlightMatch,
  query,
  isVisible,
  noMatchesItem,
  onCreateNew,
  showNoMatchesItem,
  setResultsVisible,
  maxVisibleItems,
  divId
}: ResultListProps<T>) => {
  const [focusedIndex] = useListFocus({
    divId,
    size: results.length || (showNoMatchesItem ? 1 : 0),
    handleEnter: index => {
      if (!isVisible) return;
      if (showNoMatchesItem && onCreateNew) {
        const result = results[index];
        if (result && !result.disabled) {
          onSelect(result.value);
        } else {
          onCreateNew();
        }
      } else {
        const result = results[index];

        if (!result) return;

        if (result.disabled) return;

        onSelect(result.value);
      }

      setResultsVisible(false);
    }
  });

  return (
    <div className={classNames('ComboBox-results', { 'is-visible': isVisible })}>
      <ul
        style={{
          listStyleType: 'none',
          padding: 0,
          margin: 0,
          maxHeight: maxVisibleItems ? maxVisibleItems * 50 : undefined
        }}
      >
        {results.map(({ element, value, disabled }, index) => (
          <ResultItem
            key={value}
            element={element}
            focused={index === focusedIndex}
            value={value}
            query={query}
            onSelect={onSelect}
            onMouseDown={onMouseDown}
            results={results}
            size={size}
            highlightMatch={highlightMatch}
            disabled={disabled}
          />
        ))}
        {showNoMatchesItem && (
          <li
            className={classNames('ComboBox-resultItem', {
              'is-not-creatable': !onCreateNew,
              'is-focused': focusedIndex === 0
            })}
            onClick={onCreateNew}
            key="noMatchesItem"
          >
            {noMatchesItem}
          </li>
        )}
      </ul>
    </div>
  );
};

interface ResultItemProps<T> extends ResultProps<T>, Pick<Props<T>, 'size'>, Result<T> {
  size: FieldSize;
  focused: boolean;
  query?: string;
  onMouseDown: VoidFunction;
}

const ResultItem = <T extends string | number>({
  value,
  focused,
  onSelect,
  disabled,
  // NOTE (Mark): Using this function interferes with onClickOutside in Modals
  // onMouseDown,
  element
}: ResultItemProps<T>) => {
  return (
    <li
      className={classNames('ComboBox-resultItem', { 'is-focused': focused, 'is-disabled': disabled })}
      key={value}
      onClick={() => {
        if (disabled) return;

        onSelect(value);
      }}
    >
      {element}
    </li>
  );
};

export default ComboBox;
