import React from 'react';

import { CONTENT_PADDING_X_XS, Icon, Layout, Link, NavItem, Text } from 'ds';
import { selectSelectedOfficeId } from 'store/UI/selectors';
import { selectCustomerOfficePaths, selectHasCustomerAdminRole, selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';
import { injectWorkspaceId } from 'ux/Layouts/Shared/Navigation/utils';

interface Props extends NavItem {
  displayDot?: boolean;
}

const MenuItem: React.FC<Props> = ({ href, label, iconName, onClick, target, displayDot }) => {
  const user = useAppSelector(selectUser);
  const isCustomerAdmin = useAppSelector(selectHasCustomerAdminRole);
  const selectedOfficeId = useAppSelector(selectSelectedOfficeId);
  const customerOfficePaths = useAppSelector(selectCustomerOfficePaths);

  if (!user) return null;
  return (
    <Link
      href={
        isCustomerAdmin && customerOfficePaths.map(o => o.label).includes(label) && href && selectedOfficeId
          ? injectWorkspaceId({ href, selectedOfficeId })
          : href
      }
      onClick={onClick}
      display="block"
      target={target}
    >
      <Layout paddingX={CONTENT_PADDING_X_XS} paddingY={18} align="center">
        <Icon name={iconName} size="sm" color="gray-900" />
        <Layout marginLeft={12} align="center">
          <Text size="body1" color="gray-900" semibold scale>
            {label}
          </Text>
          {!!displayDot && <Layout marginLeft={6} width={8} height={8} borderRadius="circular" color="blue-500" />}
        </Layout>
      </Layout>
    </Link>
  );
};

export default MenuItem;
