import React from 'react';
import classNames from 'classnames';
import ReactDatePicker from 'react-datepicker';

import CalendarHeader from './CalendarHeader';
import { DatePickerProps } from './types';

export interface Props extends Omit<DatePickerProps, 'selected' | 'minDate' | 'maxDate'> {
  selected?: Date | null;
  selectedDates?: Date[];
  minDate?: Date | null;
  maxDate?: Date | null;
  selectsMultiple?: boolean;
  excludeDates?: Date[];
}

const DatePickerCalendar: React.FC<Props> = ({
  selected,
  selectedDates,
  onChange,
  minDate,
  maxDate,
  onlyWeekdays,
  selectsMultiple,
  excludeDates
}) => {
  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  return (
    <ReactDatePicker
      renderCustomHeader={props => <CalendarHeader {...props} />}
      selectedDates={selectedDates}
      selected={selected}
      onChange={onChange}
      renderDayContents={dayOfMonth => {
        return <div className={classNames('Calendar-dayInner')}>{dayOfMonth}</div>;
      }}
      excludeDates={excludeDates}
      selectsMultiple={selectsMultiple}
      minDate={minDate}
      maxDate={maxDate}
      filterDate={onlyWeekdays ? isWeekday : undefined}
      shouldCloseOnSelect={!selectsMultiple}
      inline
    />
  );
};

export default DatePickerCalendar;
