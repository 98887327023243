import React, { useContext, useRef, useState } from 'react';
import { Image } from '@codiwork/codi';
import ReactQuill from 'react-quill';

import { media } from 'context';
import Button from 'ds/Button';
import Layout from 'ds/Layout';
import Lightbox from 'ds/Lightbox';
import MessageImageUploader from 'ds/MessageThread/MessageImageUploader';
import { CreatableMessage } from 'ds/MessageThread/types';
import RichTextEditor from 'ds/RichTextEditor';
import TaskImageThumbnail from 'ds/TaskImageThumbnail';
import useKeyDown from 'ds/helpers/useKeyDown';
import useKeyUp from 'ds/helpers/useKeyUp';
import { RequestState } from 'ds/types';

interface Props {
  onSubmit: (message: CreatableMessage) => Promise<void>;
}

const NEW_MESSAGE: CreatableMessage = {
  body: '<p><br></p>',
  images: []
};

const keyMap: Record<string, boolean> = {};

const MessageThreadInput: React.FC<Props> = ({ onSubmit }) => {
  const [requestState, setRequestState] = useState<RequestState>('ready');
  const [newMessage, setNewMessage] = useState<CreatableMessage>(NEW_MESSAGE);
  const ref = useRef<ReactQuill>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [lightboxStartingIndex, setLightboxStartingIndex] = useState<number>(-1);
  const { xs } = useContext(media);
  const requestInProgress = requestState === 'in_progress';
  const { body, images = [] } = newMessage;
  const isValid = !!body && body.replace(/<p><br><\/p>/g, '').trim().length > 0;
  const imagesOrEmptyArray = images;

  useKeyDown((e: KeyboardEvent) => {
    if (!newMessage.body || !ref.current?.editor?.hasFocus()) return;

    keyMap[e.key] = true;

    if (keyMap['Enter'] && keyMap['Meta']) {
      handleSubmit();
    } else if (keyMap['Enter']) {
      setRequestState('ready');
    }
  });

  const handleSubmit = async () => {
    if (requestInProgress) return;

    setRequestState('in_progress');

    await onSubmit({ body: newMessage.body.replace(/<p><br><\/p>$/, ''), images });

    setNewMessage(NEW_MESSAGE);

    setRequestState('ready');
  };

  useKeyUp((e: KeyboardEvent) => {
    keyMap[e.key] = false;
  });

  const imagesToShow = images.filter(i => typeof i.id === 'number' && !!i.key) as Image[];

  return (
    <Layout>
      {lightboxStartingIndex >= 0 && (
        <Lightbox
          images={imagesToShow}
          startingIndex={lightboxStartingIndex}
          onClose={() => setLightboxStartingIndex(-1)}
          padResize
        />
      )}
      {!!imagesToShow?.length && (
        <Layout flex wrap position="relative" left={-6} width="calc(100% + 12px)">
          {imagesToShow.map(({ key }, index) => {
            return (
              <TaskImageThumbnail
                onClick={() => setLightboxStartingIndex(index)}
                style={{
                  padding: xs ? '10px 6px' : '8px 6px',
                  width: `calc(${100 / (xs ? 3 : 6)}%)`
                }}
                key={key}
                srcKey={key || ''}
              />
            );
          })}
        </Layout>
      )}
      <RichTextEditor
        value={body}
        modules={isFocused ? undefined : { toolbar: false }}
        onChange={value => {
          setNewMessage({ ...newMessage, body: value });
        }}
        key={isFocused ? 'focused' : 'not-focused'}
        placeholder="Add to the conversation..."
        readOnly={requestInProgress}
        ref={ref}
        onFocus={() => {
          setIsFocused(true);
          setTimeout(() => {
            ref.current?.focus();
          }, 100);
        }}
        onBlur={() => setIsFocused(false)}
      />
      <Layout marginTop={8} {...(imagesOrEmptyArray.length > 0 ? {} : { align: 'center', justify: 'space-between' })}>
        <MessageImageUploader
          images={imagesOrEmptyArray}
          label="Attach image"
          onChange={files => {
            setNewMessage({
              ...newMessage,
              images: [
                ...imagesOrEmptyArray,
                ...files.map(file => {
                  return { url: URL.createObjectURL(file), file };
                })
              ]
            });
          }}
          onDeleteClick={image => {
            setNewMessage({ ...newMessage, images: imagesOrEmptyArray.filter(i => i.url !== image.url) });
          }}
          disabled={requestInProgress}
        />
        <Layout minWidth={120} justify="flex-end">
          <Button
            type="primary"
            size="sm"
            text="Comment"
            onClick={handleSubmit}
            disabled={!isValid || requestInProgress}
            showSpinner={requestInProgress}
          />
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MessageThreadInput;
