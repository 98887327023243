import React, { PropsWithChildren } from 'react';

import { Animate, Layout, PurpleGradientCircle, Text } from 'ds';

interface Props {
  animateIn?: boolean;
}

const ChatbotThreadMessage: React.FC<PropsWithChildren<Props>> = ({ children, animateIn }) => {
  return (
    <Animate align="flex-end" disableAnimation={!animateIn}>
      <PurpleGradientCircle dimension={40} />
      <Layout
        color="white"
        paddingX={12}
        paddingY={12}
        marginLeft={12}
        borderTopRightRadius={16}
        borderBottomRightRadius={16}
        borderTopLeftRadius={16}
      >
        <Text size="body-sm">{children}</Text>
      </Layout>
    </Animate>
  );
};

export default ChatbotThreadMessage;
