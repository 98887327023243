import React, { useRef, useState } from 'react';

import Clickable from './Clickable';
import Layout, { Props as LayoutProps } from './Layout';
import Text from './Text';
import { useOnClickOutside } from './helpers';

interface Props {
  children: React.ReactNode;
  options: MenuableOption[];
  containerProps?: LayoutProps;
  menuProps?: LayoutProps;
}

interface MenuableOption {
  label: string;
  onClick: VoidFunction;
  itemProps?: LayoutProps;
}

const Menuable: React.FC<Props> = ({ children, options, menuProps, ...containerProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState<number>();

  useOnClickOutside(containerRef, () => {
    setIsOpen(false);
  });

  return (
    <Layout
      position="relative"
      __ref={containerRef}
      onMeasure={() => setTop((containerRef.current?.getBoundingClientRect().height || 0) + 8)}
      {...containerProps}
    >
      <Clickable onClick={() => setIsOpen(!isOpen)}>{children}</Clickable>
      {isOpen && (
        <Layout
          position="absolute"
          top={top}
          right={0}
          color="white"
          borderRadius={12}
          zIndex={2}
          boxShadow="2px 0px 22px rgba(87, 73, 109, 0.12)"
          {...menuProps}
        >
          {options.map(option => (
            <Clickable
              key={option.label}
              onClick={() => {
                option.onClick();
                setIsOpen(false);
              }}
              hoverColor="blue-50"
              fullWidth
            >
              <Layout paddingY={16} paddingX={12} {...option.itemProps}>
                <Text size="body-xs" wrap={false} align="left">
                  {option.label}
                </Text>
              </Layout>
            </Clickable>
          ))}
        </Layout>
      )}
    </Layout>
  );
};

export default Menuable;
