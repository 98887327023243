import React from 'react';
import { DurationLike } from 'luxon';

import Layout from 'ds/Layout';
import Select from 'ds/Select';
import Text from 'ds/Text';

import { DatePickerTimeConfig, makeTimeRangeEndOptions, makeTimeRangeStartOptions } from './utils';

export interface Props {
  min: DatePickerTimeConfig;
  max: DatePickerTimeConfig;
  startTime?: string;
  endTime?: string;
  increment: DurationLike;
  onChange: TimeRangeInputsOnChange;
}

export type TimeRangeInputsOnChange = (values: { startTime?: string; endTime?: string }) => void;

const TimeRangeInputs: React.FC<Props> = ({ min, max, increment, startTime, endTime, onChange }) => {
  return (
    <Layout align="center">
      <Select
        label="Start time"
        options={makeTimeRangeStartOptions({ min, max, increment })}
        size="sm"
        onChange={({ value }) => {
          if (value === '') {
            return;
          }

          onChange({ startTime: value, endTime });
        }}
        value={startTime}
        fullWidth
        icon="clock"
        iconColor="gray-900"
        hideSmallLabel
        required
      />
      <Text size="body-xs">–</Text>
      <Select
        label="End time"
        options={makeTimeRangeEndOptions({ startTime, min, max, increment })}
        size="sm"
        onChange={({ value }) => {
          if (value === '') {
            return;
          }

          onChange({ startTime, endTime: value });
        }}
        value={endTime}
        fullWidth
        icon="clock"
        iconColor="gray-900"
        hideSmallLabel
        required
      />
    </Layout>
  );
};

export default TimeRangeInputs;
