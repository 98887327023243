import { Message, Updatable } from '@codiwork/codi';
import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { CHATBOT_HOME_SCREEN } from 'ux/Chatbot/constants';
import { ChatbotInteraction, ChatbotInteractionAPI, ChatbotThreadAPI } from 'ux/Chatbot/types';
import { SupportTicket } from 'ux/Customer/Support/types';

const initialState: ChatbotState = {
  isVisible: false,
  threads: [],
  screens: [{ value: CHATBOT_HOME_SCREEN }],
  threadInputs: [],
  pendingThreadInteractions: [],
  supportTickets: [],
  inputFocusOrdinal: 0
};

export type ChatbotState = {
  isVisible: boolean;
  threads: ChatbotThreadAPI[];
  screens: ChatbotScreen[];
  threadInputs: ChatbotThreadInput[];
  pendingThreadInteractions: PendingThreadInteraction[];
  supportTickets: SupportTicket[];
  inputFocusOrdinal: number;
};

export interface ChatbotScreen {
  value: string;
  useAnimation?: boolean;
  spawnedFromHomePrompt?: boolean;
}

export interface ChatbotThreadInput {
  threadId: number;
  input: string;
}

export interface PendingThreadInteraction {
  threadId: number;
  input: string;
}

const chatbotSlice = createSlice({
  name: 'Chatbot',
  initialState,
  reducers: {
    setIsVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.isVisible = payload;
    },
    setThreads: (state, { payload }: PayloadAction<ChatbotThreadAPI[]>) => {
      state.threads = payload;
    },
    pushScreen: (state, { payload }: PayloadAction<ChatbotScreen>) => {
      state.screens.push(payload);
    },
    replaceScreen: (state, { payload }: PayloadAction<ChatbotScreen>) => {
      if (state.screens.length) {
        state.screens[state.screens.length - 1] = payload;
      } else {
        state.screens.push(payload);
      }
    },
    addInteraction: (state, { payload }: PayloadAction<{ interaction: ChatbotInteractionAPI; threadId: number }>) => {
      const thread = state.threads.find(thread => thread.id === payload.threadId);

      if (!thread) return;

      thread.chatbot_interactions.push(payload.interaction);
    },
    updateInteraction: (
      state,
      {
        payload: { interaction, threadId }
      }: PayloadAction<{ interaction: Updatable<ChatbotInteraction>; threadId: number }>
    ) => {
      const currentState = current(state);
      const threadIndex = currentState.threads.findIndex(thread => thread.id === threadId);
      let thread = currentState.threads[threadIndex];

      if (!thread) return;

      const interactionIndex = thread.chatbot_interactions.findIndex(i => i.id === interaction.id);

      const interactionToUpdate = thread.chatbot_interactions[interactionIndex];

      if (
        interaction.updated_at &&
        DateTime.fromISO(interaction.updated_at).toMillis() <
          DateTime.fromISO(interactionToUpdate.updated_at).toMillis()
      ) {
        return;
      }
      const updatedInteraction = { ...interactionToUpdate, ...interaction };
      const updatedInteractions = [...thread.chatbot_interactions];
      updatedInteractions[interactionIndex] = updatedInteraction;

      if (interaction.support_ticket) {
        const supportTicketIndex = currentState.supportTickets.findIndex(
          ticket => ticket.id === interaction.support_ticket?.id
        );

        if (supportTicketIndex === -1) {
          state.supportTickets.push(interaction.support_ticket);
        } else {
          state.supportTickets[supportTicketIndex] = interaction.support_ticket;
        }
      }

      state.threads[threadIndex] = { ...thread, chatbot_interactions: updatedInteractions };
    },
    addThread: (state, { payload }: PayloadAction<ChatbotThreadAPI>) => {
      state.threads.unshift(payload);
    },
    setThreadInput: (state, { payload: { threadId, input } }: PayloadAction<ChatbotThreadInput>) => {
      const updatedInput = state.threadInputs.find(input => input.threadId === threadId);

      if (updatedInput) {
        updatedInput.input = input;
      } else {
        state.threadInputs.push({ threadId, input });
      }
    },
    addPendingThreadInteraction: (state, { payload }: PayloadAction<PendingThreadInteraction>) => {
      state.pendingThreadInteractions.push(payload);
    },
    removePendingThreadInteraction: (state, { payload }: PayloadAction<{ threadId: number }>) => {
      state.pendingThreadInteractions = state.pendingThreadInteractions.filter(
        ({ threadId }) => threadId !== payload.threadId
      );
    },
    setSupportTickets: (state, { payload }: PayloadAction<SupportTicket[]>) => {
      state.supportTickets = payload;
    },
    updateSupportTicket: (state, { payload }: PayloadAction<SupportTicket>) => {
      const supportTicketIndex = state.supportTickets.findIndex(ticket => ticket.id === payload.id);

      if (supportTicketIndex === -1) {
        state.supportTickets.push(payload);
      } else {
        state.supportTickets[supportTicketIndex] = payload;
      }
    },
    addSupportTicketMessage: (state, { payload }: PayloadAction<{ supportTicketId: number; message: Message }>) => {
      const supportTicketIndex = state.supportTickets.findIndex(ticket => ticket.id === payload.supportTicketId);

      if (supportTicketIndex === -1) return;

      state.supportTickets[supportTicketIndex].messages.push(payload.message);
    },
    updateSupportTicketMessage: (state, { payload }: PayloadAction<Message>) => {
      const supportTicketId = state.supportTickets.find(t => t.messages.some(m => m.id === payload.id))?.id;

      if (!supportTicketId) return;

      const supportTicketIndex = state.supportTickets.findIndex(ticket => ticket.id === supportTicketId);

      const messageIndex = state.supportTickets[supportTicketIndex].messages.findIndex(m => m.id === payload.id);

      if (messageIndex === -1) return;

      state.supportTickets[supportTicketIndex].messages[messageIndex] = payload;
    },
    incrementInputFocusOrdinal: state => {
      state.inputFocusOrdinal += 1;
    }
  }
});

export const { actions } = chatbotSlice;

export default chatbotSlice.reducer;
