import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { app, media } from 'context';
import Button from 'ds/Button';
import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import { TABLE_FILTER_SORT_HEIGHT_MOBILE, TABLE_TITLE_HEIGHT_MOBILE } from 'ds/PageLayout/constants';
import Text from 'ds/Text';
import TextButton from 'ds/TextButton';
import { lockScreenScroll, unlockScreenScroll } from 'ds/utils';

import InnerElement from './InnerElement';
import Row from './Row';
import TableProvider from './TableProvider';
import { CELL_HEIGHT } from './constants';
import {
  Column,
  ColumnWidths,
  DefaultFilterState,
  DefaultSortState,
  FilterState,
  ItemWrapperData,
  Row as RowType,
  RowHasBeenMeasured,
  RowHeights,
  SetColumnWidths,
  SetRowHeights,
  SortState
} from './types';
import { filterRows, sortRows } from './utils';
import { BOTTOM_NAVIGATION_HEIGHT } from '../constants';

export interface Props<T extends RowType> {
  rows: T[];
  columns: Column<T>[];
  dynamicRowHeight?: boolean;
  rowHeight?: number;
  headerRowCount?: number;
  defaultSortState?: DefaultSortState<T>[];
  defaultFilterState?: DefaultFilterState<T>[];
  minWidth?: number;
  title?: string;
  newPath?: string;
  newOnClick?: VoidFunction;
  CTA?: JSX.Element;
  rowOnClick?: (row: T, e: React.MouseEvent) => void;
  rowHref?: (row: T) => string;
  hideSortAndFilterResets?: boolean;
  height?: number;
  type?: 'simple' | 'default';
  LeftMobileElement?: JSX.Element;
  paddingLeft?: number;
}

const MEASURE_TIME = 50;

const Table = <T extends RowType>({
  columns,
  headerRowCount = 1,
  dynamicRowHeight = false,
  defaultSortState = [],
  defaultFilterState = [],
  minWidth,
  rowHeight = CELL_HEIGHT,
  newPath,
  newOnClick,
  title,
  CTA,
  rowOnClick,
  rowHref,
  hideSortAndFilterResets,
  type = 'default',
  LeftMobileElement,
  ...props
}: Props<T>) => {
  const history = useHistory();
  const listRef = useRef<VariableSizeList>(null);
  const rowHeightsRef = useRef<RowHeights>({});
  const rowHeightsByIdRef = useRef<RowHeights>({});
  const columnWidthsRef = useRef<ColumnWidths>({});
  const { windowHeight, appPaddingX } = useContext(app);
  const paddingLeft = props.paddingLeft ?? appPaddingX;
  const { xs, sm } = useContext(media);
  const isMobile = xs || sm;
  const { sort, filters } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const sortParams: SortState[] = sort ? (JSON.parse(sort as string) as SortState[]) : [];
  const filterParams: FilterState[] = filters
    ? (JSON.parse(filters as string) as FilterState[]).map(filter => {
        if (filter.endDate) {
          filter.endDate = new Date(filter.endDate);
        }
        if (filter.startDate) {
          filter.startDate = new Date(filter.startDate);
        }
        return filter;
      })
    : [];
  const [sortState, setSortState] = useState<SortState[]>(
    sortParams.length > 0 ? sortParams : (defaultSortState as SortState[])
  );
  const [filterState, setFilterState] = useState<FilterState[]>(
    filterParams.length > 0 ? filterParams : (defaultFilterState as FilterState[])
  );
  const [tableHeight, setTableHeight] = useState<number>();

  const rows = sortRows({ rows: filterRows({ rows: props.rows, filterState, columns }), columns, sortState });

  const getRowHeight = (index: number) => {
    if (!dynamicRowHeight) {
      return rowHeight;
    }

    return rowHeightsRef.current[index] || rowHeight;
  };

  const getRowHeightById = (id: number | string) => {
    return rowHeightsByIdRef.current[id];
  };

  const setRowHeights: SetRowHeights = rowHeights => {
    rowHeightsRef.current = { ...rowHeightsRef.current, ...rowHeights };
  };

  const setRowHeightsById: SetRowHeights = rowHeights => {
    rowHeightsByIdRef.current = { ...rowHeightsByIdRef.current, ...rowHeights };
  };

  const rowHasBeenMeasured: RowHasBeenMeasured = id => {
    if (!dynamicRowHeight) {
      return false;
    }

    return !!rowHeightsByIdRef.current[id];
  };

  const resetListCache = () => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(headerRowCount);
    }
  };

  useEffect(() => {
    // Reset dimensions after the first render so table row heights use measured heights.
    dynamicRowHeight && setTimeout(resetListCache, MEASURE_TIME);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type === 'simple') return;

    if (xs) {
      lockScreenScroll();
    }

    return () => {
      if (xs) {
        unlockScreenScroll();
      }
    };
  }, [xs, type]);

  const setColumnWidths: SetColumnWidths = columnWidths => {
    const lastMeasuredCount = Object.values(columnWidthsRef.current).filter(v => v !== undefined).length;

    columnWidthsRef.current = { ...columnWidthsRef.current, ...columnWidths };

    const measuredCount = Object.values(columnWidthsRef.current).filter(v => v !== undefined).length;

    if (measuredCount === columns.length && lastMeasuredCount < columns.length) {
      resetListCache();
    }
  };

  const handleSetFilterState = (filterState: FilterState[]) => {
    setFilterState(filterState);
    const filterParams = new URLSearchParams({ filters: JSON.stringify(filterState) });
    const searchParams = new URLSearchParams({ sort: JSON.stringify(sortState) });
    history.replace({
      pathname: history.location.pathname,
      search: `${filterParams.toString()}&${searchParams.toString()}`
    });

    if (dynamicRowHeight) {
      rowHeightsRef.current = {};
      setTimeout(resetListCache, MEASURE_TIME);
    }
  };

  const handleSetSortState = (sortState: SortState[]) => {
    setSortState(sortState);
    const filterParams = new URLSearchParams({ filters: JSON.stringify(filterState) });
    const searchParams = new URLSearchParams({ sort: JSON.stringify(sortState) });
    history.replace({
      pathname: history.location.pathname,
      search: `${filterParams.toString()}&${searchParams.toString()}`
    });

    if (dynamicRowHeight) {
      rowHeightsRef.current = {};
      setTimeout(resetListCache, MEASURE_TIME);
    }
  };

  const isDefaultType = type === 'default';
  const calculatedMinWidth = columns.every(c => c.width && typeof c.width === 'number')
    ? columns.reduce((sum, c) => sum + Number(c.width), 0)
    : undefined;

  return (
    <Layout flexGrow={1}>
      {isDefaultType && (!!title || !!newPath || !!newOnClick || !!CTA) && (
        <Layout
          paddingX={paddingLeft}
          align="center"
          justify="space-between"
          {...(isMobile ? { height: TABLE_TITLE_HEIGHT_MOBILE } : { paddingTop: 24, paddingBottom: 16 })}
        >
          <Layout align="center" height={44}>
            {title && (
              <Text size="h5" ellipsis>
                {title}
              </Text>
            )}
          </Layout>
          {(newPath || newOnClick) &&
            (isMobile ? (
              <IconButton
                size="lg"
                name="plus"
                type="noBackground"
                onClick={
                  newOnClick ||
                  (() => {
                    window.open(newPath, '_blank');
                  })
                }
              />
            ) : (
              <Button
                type="primary"
                href={newPath}
                size="xxs"
                icon="plus"
                iconPosition="left"
                onClick={newOnClick}
                text="Add"
              />
            ))}
          {CTA}
        </Layout>
      )}
      {!hideSortAndFilterResets && (
        <Layout
          paddingX={paddingLeft}
          justify={LeftMobileElement ? 'space-between' : 'flex-end'}
          {...(isMobile
            ? {
                height: TABLE_FILTER_SORT_HEIGHT_MOBILE,
                paddingBottom: 24,
                paddingTop: 16,
                align: 'center'
              }
            : {})}
        >
          {LeftMobileElement}
          <Layout align="center">
            <TextButton
              scale
              text="Reset filters"
              {...(xs ? { textSize: 'body3' } : { textSize: 'body2' })}
              color="gray-700"
              onClick={() => handleSetFilterState(defaultFilterState as FilterState[])}
            />
            {isMobile && (
              <Layout marginLeft={8} align="center">
                <Text color="gray-100" size="body3">
                  |
                </Text>
                <Layout marginLeft={8}>
                  <TextButton
                    scale
                    text="Clear sort"
                    {...(xs ? { textSize: 'body3' } : { textSize: 'body2' })}
                    color="gray-700"
                    onClick={() => handleSetSortState([])}
                  />
                </Layout>
              </Layout>
            )}
          </Layout>
        </Layout>
      )}
      <Layout
        {...(isDefaultType ? { marginTop: isMobile ? undefined : 12, paddingLeft: paddingLeft } : {})}
        onMeasure={({ y }) => {
          if (type === 'simple') {
            setTableHeight(props.height || y);
          } else {
            setTableHeight(windowHeight - y - (isMobile ? BOTTOM_NAVIGATION_HEIGHT + 12 : 0));
          }
        }}
      />
      {tableHeight && (
        <Layout
          {...(xs
            ? {
                height: `calc(100dvh - ${
                  TABLE_TITLE_HEIGHT_MOBILE + TABLE_FILTER_SORT_HEIGHT_MOBILE + BOTTOM_NAVIGATION_HEIGHT
                }px)`
              }
            : { height: tableHeight })}
          {...(isDefaultType ? { paddingLeft: paddingLeft } : {})}
          flex
        >
          <AutoSizer
            onResize={() => {
              const resetWidths = columns.reduce<ColumnWidths>((widths, column) => {
                widths[column.key as string] = undefined;

                return widths;
              }, {});

              setColumnWidths(resetWidths);
              rowHeightsRef.current = {};
              rowHeightsByIdRef.current = {};
            }}
          >
            {({ width, height }) => (
              <TableProvider
                height={height}
                minWidth={minWidth || calculatedMinWidth}
                innerElementType={InnerElement}
                itemCount={rows.length + headerRowCount}
                itemSize={getRowHeight}
                estimatedItemSize={dynamicRowHeight ? undefined : rowHeight}
                headerIndices={new Array(headerRowCount).fill(0).map((_, index) => index)}
                width={width}
                rows={rows}
                columns={columns}
                sortState={sortState}
                setSortState={handleSetSortState}
                listRef={listRef}
                getRowHeightById={getRowHeightById}
                setRowHeights={setRowHeights}
                setRowHeightsById={setRowHeightsById}
                rowHasBeenMeasured={rowHasBeenMeasured}
                rowHeight={rowHeight}
                setColumnWidths={setColumnWidths}
                getColumnWidth={(key: string) => {
                  return columnWidthsRef.current[key];
                }}
                style={{ paddingRight: isDefaultType ? paddingLeft : undefined }}
                dynamicRowHeight={dynamicRowHeight}
                filterState={filterState}
                setFilterState={handleSetFilterState}
                // @ts-expect-error
                rowOnClick={rowOnClick}
                // @ts-expect-error
                rowHref={rowHref}
                onItemsRendered={dynamicRowHeight ? resetListCache : undefined}
                itemKey={(index, data: ItemWrapperData<T>) => {
                  const row = data.rows[index];

                  return row ? `id-${row.id}` || index : index;
                }}
              >
                {Row}
              </TableProvider>
            )}
          </AutoSizer>
        </Layout>
      )}
    </Layout>
  );
};

export default Table;
