import React, { useState } from 'react';
import { EditableImage } from '@codiwork/codi';

import { showSnackbar } from 'helpers/snackbar';

import { Animate, Layout, PillButton, RequestState, Text, TextArea } from 'ds';
import MessageImageUploader from 'ds/MessageThread/MessageImageUploader';
import { actions as chatbotActions } from 'store/Chatbot';
import { useAppDispatch } from 'store/hooks';
import { updateSupportTicket } from 'ux/Customer/Support/requests';

import ChatbotThinkingDisplay from '../ChatbotThinkingDisplay';
import { resolveIntent } from '../requests';
import { ChatbotInteraction } from '../types';

interface Props {
  chatbotInteraction: ChatbotInteraction;
  threadId: number;
  animateIn?: boolean;
  animationDelay?: number;
}

const MAX_DESCRIPTION_LENGTH = 576;

const ChatbotCreateSupportTicket: React.FC<Props> = ({ chatbotInteraction, threadId, animateIn, animationDelay }) => {
  const [requestState, setRequestState] = useState<RequestState>('ready');
  const [description, setDescription] = useState<string>(chatbotInteraction.input);
  const dispatch = useAppDispatch();
  const [images, setImages] = useState<EditableImage[]>([]);

  const createSupportTicket = async () => {
    setRequestState('in_progress');
    const slots = { ...chatbotInteraction.slots, description, createSupportTicket: true };

    try {
      const { data } = await resolveIntent({ chatbot_interaction_id: chatbotInteraction.id, slots });
      dispatch(chatbotActions.updateInteraction({ threadId: threadId, interaction: data }));

      const supportTicket = data.supportTicket;
      if (supportTicket && images.length) {
        const { data: updatedSupportTicket } = await updateSupportTicket({
          supportTicket: { id: supportTicket.id, images }
        });
        dispatch(chatbotActions.updateSupportTicket({ ...updatedSupportTicket }));
      }
    } catch (error) {
      debugger;
      showSnackbar({ negative: true });
    } finally {
      setRequestState('ready');
    }
  };

  const requestInProgress = requestState === 'in_progress';

  return requestState === 'in_progress' ? (
    <Layout
      paddingX={16}
      paddingY={24}
      color="white"
      borderRadius={16}
      direction="column"
      align="center"
      justify="center"
    >
      <Text size="body-md" semibold>
        Submitting ticket
      </Text>
      <Layout marginTop={16}>
        <ChatbotThinkingDisplay />
      </Layout>
    </Layout>
  ) : (
    <Animate
      paddingX={16}
      paddingY={16}
      color="white"
      borderRadius={16}
      disableAnimation={!animateIn}
      delay={animationDelay}
    >
      <Layout align="center">
        <Text size="body-md" semibold>
          Create support ticket
        </Text>
      </Layout>
      <Text size="body-sm" color="gray-700">
        Please describe the issue to the property manager. The more details you provide, the better they can address the
        issue.
      </Text>
      <Layout marginTop={16} gap={4} direction="column">
        <TextArea
          size="sm"
          value={description}
          label="Description of issue"
          placeholder="Please describe the issue you're experiencing"
          onChange={({ value }) => setDescription(value)}
          maxLength={MAX_DESCRIPTION_LENGTH}
          minHeight={100}
        />
        <Layout marginTop={8}>
          <MessageImageUploader
            images={images}
            label="Attach image"
            onChange={files => {
              setImages([
                ...images,
                ...files.map(file => {
                  return { url: URL.createObjectURL(file), file };
                })
              ]);
            }}
            onDeleteClick={image => {
              setImages(images.filter(i => i.url !== image.url));
            }}
            disabled={requestInProgress}
            imageDimension={72}
          />
        </Layout>
        <Layout marginTop={32}>
          <PillButton
            size="md"
            type="purpleGradient"
            onClick={createSupportTicket}
            text="Submit"
            fullWidth
            showSpinner={requestInProgress}
            disabled={requestInProgress || !description}
          />
        </Layout>
      </Layout>
    </Animate>
  );
};

export default ChatbotCreateSupportTicket;
