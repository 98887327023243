import React, { CSSProperties, useState } from 'react';

import Clickable from 'ds/Clickable';
import Img from 'ds/Img';
import Layout from 'ds/Layout';

interface Props {
  onClick: VoidFunction;
  style?: CSSProperties;
  srcKey: string;
}

const TaskImageThumbnail: React.FC<Props> = ({ onClick, style, srcKey }) => {
  const [height, setHeight] = useState<number>();

  return (
    <Clickable onClick={onClick} style={style}>
      <Layout onMeasure={({ width }) => setHeight(width)} height={height}>
        <Img
          srcKey={srcKey}
          alt="task image"
          width={320}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 8
          }}
        />
      </Layout>
    </Clickable>
  );
};

export default TaskImageThumbnail;
