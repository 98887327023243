import { createSelector } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { RootState } from 'store/store';
import { parseChatbotThreadId } from 'ux/Chatbot/utils';

export const selectChatbotIsVisible = (state: RootState) => state.Chatbot.isVisible;
export const selectChatbotScreen = (state: RootState) => state.Chatbot.screens.slice(-1)[0];
export const selectCustomerSupportTickets = (state: RootState) => state.Chatbot.supportTickets;
export const selectCustomerSupportTicketByInteractionId = (interactionId: number) =>
  createSelector(selectCustomerSupportTickets, supportTickets =>
    supportTickets.find(ticket => ticket.chatbot_interaction_id === interactionId)
  );
const selectThreads = (state: RootState) => state.Chatbot.threads;

export const selectChatbotThreads = createSelector(
  selectThreads,
  selectCustomerSupportTickets,
  (threads, supportTickets) => {
    const threadsWithSupportTickets = [
      ...threads.map(t => ({
        ...t,
        chatbot_interactions: t.chatbot_interactions.map(i => ({
          ...i,
          support_ticket: i.support_ticket_id ? supportTickets.find(st => st.id === i.support_ticket_id) || null : null
        }))
      }))
    ];
    return threadsWithSupportTickets.sort((a, b) => {
      const interactions_a = a.chatbot_interactions;
      const interactions_b = b.chatbot_interactions;
      const lastInteraction_a = interactions_a[interactions_a.length - 1];
      const lastInteraction_b = interactions_b[interactions_b.length - 1];

      if (!lastInteraction_a) return 0;
      if (!lastInteraction_b) return 1;

      return (
        DateTime.fromISO(lastInteraction_b.created_at).toMillis() -
        DateTime.fromISO(lastInteraction_a.created_at).toMillis()
      );
    });
  }
);
export const selectChatbotActiveThread = createSelector(
  selectChatbotThreads,
  selectChatbotScreen,
  (threads, screen) => {
    const threadId = parseChatbotThreadId(screen.value);
    if (!threadId) return null;

    return threads.find(thread => thread.id === threadId);
  }
);
export const selectActiveChatbotThread = createSelector(
  selectChatbotActiveThread,
  selectChatbotThreads,
  (activeThread, threads) => threads.find(thread => thread.id === activeThread?.id)
);

export const selectLatestChatbotThread = createSelector(selectChatbotThreads, threads => threads[0]);
export const selectChatbotPreviousScreen = (state: RootState) => state.Chatbot.screens.slice(-2)[0];
export const selectThreadInputs = (state: RootState) => state.Chatbot.threadInputs;
const selectPendingThreadInteractions = (state: RootState) => state.Chatbot.pendingThreadInteractions;
export const selectPendingThreadInteraction = (threadId: number) =>
  createSelector(selectPendingThreadInteractions, pendingInteractions =>
    pendingInteractions.find(pi => pi.threadId === threadId)
  );
export const selectInputFocusOrdinal = (state: RootState) => state.Chatbot.inputFocusOrdinal;
