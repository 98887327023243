import React from 'react';

import Billing from './Billing';
import Consumables from './Consumables';
import Conversation from './Conversation';
import OrderCleaning from './OrderCleaning';
import OrderFurniture from './OrderFurniture';
import SupportTicket from './SupportTicket';
import { ChatbotIntentProps } from '../types';

interface Props extends ChatbotIntentProps {}

const ChatbotWidget: React.FC<Props> = ({ chatbotInteraction, threadId }) => {
  switch (chatbotInteraction.intent) {
    case 'OrderCleaningIntent':
      return <OrderCleaning chatbotInteraction={chatbotInteraction} threadId={threadId} />;
    case 'OrderFurnitureIntent':
      return <OrderFurniture chatbotInteraction={chatbotInteraction} threadId={threadId} />;
    case 'ConsumablesIntent':
      return <Consumables chatbotInteraction={chatbotInteraction} threadId={threadId} />;
    case 'BillingIntent':
      return <Billing chatbotInteraction={chatbotInteraction} threadId={threadId} />;
    case 'SupportTicketIntent':
      return <SupportTicket chatbotInteraction={chatbotInteraction} threadId={threadId} />;
    default:
      return <Conversation chatbotInteraction={chatbotInteraction} threadId={threadId} />;
  }
};

export default ChatbotWidget;
