import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useWindowScroll } from 'beautiful-react-hooks';
import classNames from 'classnames';

import { HEADER_Z_INDEX, Layout, NavItem } from 'ds';
import { actions } from 'store/UI';
import { selectSelectedOfficeId, selectUIState } from 'store/UI/selectors';
import {
  selectCustomerOfficePaths,
  selectCustomerRequests,
  selectHasCustomerAdminRole,
  selectUser
} from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import NavIcon from './NavIcon';
import { OfficePath } from './constants';
import { injectWorkspaceId } from './utils';
import { hrefIsMatch, isNavigationItemActive } from '../utils';

interface Props {
  items: NavItem[];
  theme: 'light' | 'dark';
  moreNavItems: NavItem[];
}

const BottomNavigation: React.FC<Props> = ({ items, theme, moreNavItems = [] }) => {
  const { pathname } = useLocation();
  const { bottomNavigationIsVisible } = useAppSelector(selectUIState);
  const scrollY = useRef<number>(window.scrollY);
  const initialScrollUpY = useRef<number>();
  const initialScrollDownY = useRef<number>();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const customerOfficePaths = useAppSelector(selectCustomerOfficePaths);

  const requests = useAppSelector(selectCustomerRequests);
  const openRequests = requests.filter(request => request.status === 'open');
  const isCustomerAdmin = useAppSelector(selectHasCustomerAdminRole);

  const hasOpenRequests = !!openRequests.length;

  const selectedOfficeId = useAppSelector(selectSelectedOfficeId);

  const shouldDisplayDot = (label: string) => {
    return (label === 'Requests' && hasOpenRequests) || label === 'Favorites';
  };

  const handleScroll = () => {
    if (initialScrollUpY.current && initialScrollUpY.current - window.scrollY > 30) {
      dispatch(actions.updateUIState({ bottomNavigationIsVisible: true }));
      initialScrollUpY.current = undefined;
      initialScrollDownY.current = undefined;
    } else if (window.scrollY < scrollY.current && !initialScrollUpY.current) {
      initialScrollUpY.current = window.scrollY;
    } else if (
      initialScrollDownY.current &&
      window.scrollY > scrollY.current &&
      window.scrollY > 100 &&
      window.scrollY - initialScrollDownY.current > 30
    ) {
      dispatch(actions.updateUIState({ bottomNavigationIsVisible: false }));
      initialScrollUpY.current = undefined;
      initialScrollDownY.current = undefined;
    } else if (window.scrollY > scrollY.current && !initialScrollDownY.current) {
      initialScrollDownY.current = window.scrollY;
    } else if (window.scrollY < 30) {
      dispatch(actions.updateUIState({ bottomNavigationIsVisible: true }));
    }

    scrollY.current = window.scrollY;
  };
  const onWindowScroll = useWindowScroll();
  onWindowScroll(handleScroll);

  if (!user) return null;

  return (
    <Layout
      zIndex={HEADER_Z_INDEX}
      color={theme === 'light' ? 'white' : 'blue-800'}
      width="100%"
      paddingX={12}
      __className={classNames('BottomNavigation BottomNavigationSlide', {
        'bottom-navigation-is-visible': bottomNavigationIsVisible
      })}
      boxShadow="rgba(87, 73, 109, 0.12) 2px 0 22px 0"
    >
      <Layout flex>
        {items.map(item => {
          const { key, iconName, href, label, exact, matchHrefs, mobileLabel } = item;
          const isMoreItem = label === 'More';

          const injectedHref =
            isCustomerAdmin && customerOfficePaths.map(o => o.label).includes(label) && href && selectedOfficeId
              ? injectWorkspaceId({ href, selectedOfficeId })
              : href;

          const isInMoreNavItems = isItemInMoreNavItems({
            moreNavItems,
            isCustomerAdmin,
            pathname,
            selectedOfficeId,
            customerOfficePaths
          });

          return (
            <Layout width={`${(100 / items.length).toFixed(2)}%`} key={label} justify="center">
              <NavIcon
                key={key}
                iconName={iconName}
                href={injectedHref}
                label={mobileLabel ? mobileLabel : label}
                theme={theme}
                displayDot={shouldDisplayDot(label)}
                isActive={
                  isNavigationItemActive({ href: injectedHref, matchHrefs, exact, pathname }) ||
                  (isMoreItem && isInMoreNavItems)
                }
              />
            </Layout>
          );
        })}
      </Layout>
    </Layout>
  );
};

function isItemInMoreNavItems({
  moreNavItems,
  isCustomerAdmin,
  selectedOfficeId,
  customerOfficePaths,
  pathname
}: {
  moreNavItems: NavItem[];
  isCustomerAdmin: boolean;
  selectedOfficeId: number | null;
  customerOfficePaths: OfficePath[];
  pathname: string;
}) {
  return moreNavItems.some(({ href, exact, matchHrefs, label }) => {
    const injectedHref =
      isCustomerAdmin && customerOfficePaths.map(o => o.label).includes(label) && href && selectedOfficeId
        ? injectWorkspaceId({ href, selectedOfficeId })
        : href;

    const matchedHrefsMatch = !!matchHrefs?.some(matchedHref => {
      const matchedInjectedHref =
        typeof matchedHref.href === 'string' &&
        isCustomerAdmin &&
        customerOfficePaths.map(o => o.label).includes(label) &&
        matchedHref.href &&
        selectedOfficeId
          ? injectWorkspaceId({ href: matchedHref.href, selectedOfficeId })
          : matchedHref.href;

      return hrefIsMatch({ href: matchedInjectedHref, exact: matchedHref.exact, pathname });
    });

    if (pathname === '/app/calendar' && (hrefIsMatch({ href: injectedHref, exact, pathname }) || matchedHrefsMatch)) {
      // TODO: Remove debugger
      // debugger;
    }

    return hrefIsMatch({ href: injectedHref, exact, pathname }) || matchedHrefsMatch;
  });
}

export default BottomNavigation;
