import React from 'react';
import classNames from 'classnames';

import { Size } from './Text';
import { DS_CLASS_NAME } from './constants';

interface Props {
  value: string;
  textSize?: Size;
}

const RichTextDisplay: React.FC<Props> = ({ value, textSize }) => {
  return (
    <div
      className={classNames('RichTextDisplay ql-editor', 'Text is-color-gray-900', `is-${textSize}`, DS_CLASS_NAME)}
      dangerouslySetInnerHTML={{ __html: value }}
    ></div>
  );
};

export default RichTextDisplay;
