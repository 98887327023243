import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { logout } from 'helpers/auth';

import { HOME_PATH } from 'routes';

const Logout: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    logout({ history });

    history.push(HOME_PATH);
  }, [history]);

  return null;
};

export default Logout;
