import React from 'react';

import { Layout } from 'ds';
import ChatbotThreadCustomerMessage from 'ux/Chatbot/ChatbotThreadCustomerMessage';
import ChatbotThreadMessage from 'ux/Chatbot/ChatbotThreadMessage';
import { ChatbotIntentProps } from 'ux/Chatbot/types';

import ChatbotViewSupportTicket from '../ChatbotViewSupportTicket';

interface Props extends ChatbotIntentProps {}

const Conversation: React.FC<Props> = ({ chatbotInteraction }) => {
  const { llm_conversation } = chatbotInteraction;

  if (!llm_conversation) {
    return null;
  }

  const { llm_messages } = llm_conversation;

  return (
    <Layout>
      {llm_messages.map((message, index) => (
        <React.Fragment key={message.id}>
          {message.role === 'user' && index > 0 && (
            <Layout marginLeft={24} justify="flex-end">
              <ChatbotThreadCustomerMessage>
                <div dangerouslySetInnerHTML={{ __html: message.content }} />
              </ChatbotThreadCustomerMessage>
            </Layout>
          )}
          {message.role === 'assistant' && (
            <Layout marginTop={16}>
              <ChatbotThreadMessage>
                <div dangerouslySetInnerHTML={{ __html: message.content }} />
              </ChatbotThreadMessage>
            </Layout>
          )}
        </React.Fragment>
      ))}
      {chatbotInteraction.support_ticket && (
        <Layout marginTop={16}>
          <ChatbotViewSupportTicket supportTicket={chatbotInteraction.support_ticket} />
        </Layout>
      )}
    </Layout>
  );
};

export default Conversation;
